import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BackgroundSection from "../components/BackgroundSection"
import MainContent from "../components/atoms/MainContent"
import Heading from "../components/atoms/Heading";
import Paragraph from "../components/atoms/Paragraph"
import {css} from "@emotion/core";

const ContainerStyles = css`
  padding: 80px;
  text-align: left;
  @media(max-width: 767px) {
    padding: 30px;
  }
`

const ProfilePage = () => (
  <Layout>
    <BackgroundSection>
      <SEO title="Profile Page" />
      <MainContent>
        <div css={ContainerStyles}>
          <Heading level={2}>プロフィール</Heading>
          <Paragraph>
            沖縄出身、中央大学商学部経営学科卒業。
            PR会社のディレクターからエンジニアに転職後、
            主にソーシャルゲーム開発のサーバーサイドとして運用・新規開発を担当。
            在籍中はリードエンジニアやインフラチームでの業務も経験。
          </Paragraph>
          <Paragraph>
            フリーランスになってからはフロントエンドの業務も行う。
            最近の案件で使用した技術は下記。
          </Paragraph>
          <Paragraph>
            Golang | Kubernetes | GraphQL | gRPC | TypeScript | React.js | Vue.js
          </Paragraph>
          <Paragraph>
            プライベートでは技術ブログを書いたり、仮想通貨の自動売買ボットスクリプトを作成したり、個人サービスの開発を行っている。
          </Paragraph>
          <br/>

          <Heading level={2}>職歴</Heading>
          <Heading level={3}>- フリーランス(2017~)</Heading>
          <Paragraph>主にWebサービス系企業でフロント・バックエンド問わず運用・新規開発を担当。</Paragraph>
          <Heading level={3}>- 株式会社アプリボット(2013~2017)</Heading>
          <Paragraph>主にJava言語でのサーバーサイドエンジニアで運用・新規開発を担当。</Paragraph>
          <Heading level={3}>- 株式会社PRTIMES(2012~2013)</Heading>
          <Paragraph>サービスのディレクションやwordpressの業務を担当。</Paragraph>
          {/*
          <Paragraph>
            技術経歴書は
            <a href="https://docs.google.com/spreadsheets/d/1Y12pkP44mUU597cY0VoyWPpQEKnKwsmLzDLHYait-Eo/edit?usp=sharing" target="_blank" rel="noopener noreferrer">こちら</a>
          </Paragraph>
          */}
          <br/>

          <Heading level={2}>資格</Heading>
          <Paragraph>AWSソリューションアーキテクト アソシエイト(2019)</Paragraph>
          <Paragraph>基本情報技術者(2012)</Paragraph>
        </div>
      </MainContent>
    </BackgroundSection>
  </Layout>
)

export default ProfilePage
